.img-loading {
    background-image: url('/images/cog.svg');
}
.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #434C56;
}

.loading-animation {
    display: flex;
    margin-left: 45px;
}

.loading .img-loading {
    animation-name: loading-spin;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.loading-big {
    width: 88px;
    height: 88px;
    margin-bottom: 15px;
    animation-duration: 5s;
}

.loading-big2 {
    width: 88px;
    height: 88px;
    margin-bottom: 35px;
    animation-duration: 5s;
    animation-direction: reverse;
    position: relative;
    top: -30px;
    left: -3px
}

.loading-small {
    width: 60px;
    height: 60px;
    align-self: flex-end;
    margin-left: -10px;
    animation-duration: 5s;
    animation-direction: reverse;
    position: relative;
    left: -88px;
    top: -5px
}

.loading-text {
    font-size: 28px;
    font-weight: bold;
}

@keyframes loading-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
