html {
    scroll-behavior: smooth;
}

@media(min-width: 1024px) {
    .wrapper.card {
        min-width: 1200px;
    }
}

.select-wrapper {
    position: relative;
}

.remove-selected {
    color: #950707;
    position: absolute;
    top: -4px;
    right: 7px;
    font-size: 1.8em;
    opacity: .7;
    cursor: pointer;
    transition: all .4s;
}
.remove-selected:hover {
    opacity: 1;
}
.image.slotie-image {
    max-height: 359px;
}
